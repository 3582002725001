// paths
import { stakeholderPaths } from "common/data/routePaths";
import { lazy } from "react";
// components
const Reinsurer = lazy(() => import("pages/Stakeholders/reinsurer"));
const Physician = lazy(() => import("pages/Stakeholders/physician"));
// health care providers
const HealthCareProvider = lazy(() =>
  import("pages/Stakeholders/HealthCareProvider")
);
const HealthCareProviderTabs = lazy(() =>
  import("pages/Stakeholders/HealthCareProvider/HealthCareProviderTabs")
);
// payer
const Payers = lazy(() => import("pages/Stakeholders/payers"));
const PayerTabs = lazy(() => import("pages/Stakeholders/payers/tabs"));
// beneficiary list
const BeneficiaryList = lazy(() =>
  import("pages/Stakeholders/BeneficiaryList")
);

const UpdateTabs = lazy(() =>
  import("pages/Stakeholders/BeneficiaryList/UpdateTabs")
);
const AddBeneficiary = lazy(() =>
  import("pages/Stakeholders/BeneficiaryList/AddBeneficiary")
);
const BeneficiariesRenewal = lazy(() =>
  import("pages/Stakeholders/BeneficiariesRenewal")
);
const SubBeneficiariesRenewal = lazy(() =>
  import("pages/Stakeholders/SubBeneficiariesRenewal")
);
const SubBeneficiariesList = lazy(() =>
  import("pages/Stakeholders/SubBeneficiariesList")
);
const Broker = lazy(() => import("pages/Stakeholders/Broker"));
const BrokersForms = lazy(() =>
  import("pages/Stakeholders/Broker/BrokersForms")
);
const UploadPhysicians = lazy(() =>
  import("pages/Stakeholders/physician/UploadPhysicians")
);
const UploadProviderServices = lazy(() =>
  import(
    "pages/Stakeholders/HealthCareProvider/HealthCareProviderServices/UploadProviderServices"
  )
);
const BeneficiaryPrint = lazy(() =>
  import("pages/Stakeholders/BeneficiaryList/BeneficiaryPrint")
);
const ComputeAndValidate = lazy(() =>
  import("pages/Stakeholders/ComputeAndValidate")
);
const RenewalOffer = lazy(() =>
  import("pages/Stakeholders/BeneficiariesRenewal/RenewalOfferPdf/RenewalOffer")
);

// installments Print
const InstallmentDivision = lazy(() =>
  import(
    "pages/Stakeholders/BeneficiaryList/UpdateTabs/InstallmentPdf/installmentDivision"
  )
);

// Print Card
const PrintCard = lazy(() =>
  import("pages/Stakeholders/BeneficiaryList/PrintCard")
);
const BulkCardPrint = lazy(() =>
  import("pages/Production/Policies/SecondTab/BulkCard/BulkCardPrint")
);
// destructure the stakeholders paths object
const {
  reinsurer,
  physician,
  payer,
  healthcare_providers,
  beneficiaryList,
  broker,
  bulkPrintCards,
} = stakeholderPaths;

export const Stakeholders = [
  { path: reinsurer, component: Reinsurer },
  { path: physician, component: Physician },
  { path: `${physician}/upload`, component: UploadPhysicians },
  // health care providers
  {
    path: healthcare_providers,
    component: HealthCareProvider,
  },
  {
    path: `${healthcare_providers}/add`,
    component: HealthCareProviderTabs,
  },
  {
    path: `${healthcare_providers}/edit/:id`,
    component: HealthCareProviderTabs,
  },
  {
    path: `${healthcare_providers}/view/:id`,
    component: HealthCareProviderTabs,
  },
  {
    path: `${healthcare_providers}/:id/import`,
    component: UploadProviderServices,
  },

  // payers
  { path: payer, component: Payers },
  { path: `${payer}/add`, component: PayerTabs },
  { path: `${payer}/edit/:id`, component: PayerTabs },
  { path: `${payer}/view/:id`, component: PayerTabs },

  // Beneficiary List
  { path: beneficiaryList, component: BeneficiaryList },

  //Add Beneficiary
  {
    path: `${beneficiaryList}/:policyId/add`,
    component: AddBeneficiary,
  },

  //Add Sub Beneficiary Beneficiary
  {
    path: `${beneficiaryList}/:policyId/add/:parentId`,
    component: AddBeneficiary,
  },
  //Edit Beneficiary
  {
    path: `${beneficiaryList}/edit/:beneficiaryId`,
    component: UpdateTabs,
  },
  //View Beneficiary
  {
    path: `${beneficiaryList}/view/:beneficiaryId`,
    component: UpdateTabs,
  },
  //Print Beneficiary
  {
    path: `${beneficiaryList}/print/:beneficiaryId/:policyId`,
    component: BeneficiaryPrint,
  },

  //Print Card
  {
    path: `${beneficiaryList}/print-card/:beneficiaryId`,
    component: PrintCard,
  },

  //Print installment
  {
    path: `${beneficiaryList}/installment/print/:beneficiaryId`,
    component: InstallmentDivision,
  },

  //Beneficiary Renewal
  { path: `${beneficiaryList}/renewal`, component: BeneficiariesRenewal },
  //Beneficiary Renewal Print
  { path: `${beneficiaryList}/renewal/print`, component: RenewalOffer },

  //SubBeneficiary Renewal
  //beneficiaryId:principle Id
  {
    path: `${beneficiaryList}/renewal/:beneficiaryId`,
    component: SubBeneficiariesRenewal,
  },

  //Policy Compute And Validate Beneficiaries
  {
    path: `${beneficiaryList}/compute/:policyId`,
    component: ComputeAndValidate,
  },

  //Sub Beneficiary List

  { path: "/sub-beneficiary-list/:parentId", component: SubBeneficiariesList },
  {
    path: `${beneficiaryList}/${bulkPrintCards}/:policyId`,
    component: BulkCardPrint,
  },
  {
    path: `${beneficiaryList}/${bulkPrintCards}/:policyId/:beneficiaryIDs`,
    component: BulkCardPrint,
  },

  /* Borkers */

  //index
  { path: broker, component: Broker },
  //Add
  { path: `${broker}/add`, component: BrokersForms },
  //Edit
  { path: `${broker}/edit/:id`, component: BrokersForms },
  //Edit
  { path: `${broker}/view/:id`, component: BrokersForms },
];
