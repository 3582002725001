import { stakeholderPaths } from "common/data/routePaths";
import SpecialNotesModal from "components/TP/Common/Modals/SpecialNotesModal";
import MultiSkeleton from "components/TP/Common/MultiSkeleton";
import React, { Fragment, Suspense, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Badge, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { beneficaryBenefitTOBId } from "utils/modulesIds";
import MedicalFilesTableModal from "./MedicalFilesTableModal";
import { benefitIds, buttonsTitles, modalsStates } from "common/data";
import NetworkContractsModal from "./NetworkContractsModal";
import ChatModal from "../Common/Modals/ChatModal";
import WhatssAppModal from "./WhatssAppModal";
import FocSpentModal from "../Beneficiary/FocSpentModal";

const BeneficiaryLimits = React.lazy(() =>
  import("./TopThreeBoxes/BeneficiaryLimits")
);

const ClaimsBreadcrumbAddons = ({
  beneficiary,
  isLoading,
  claim,
  networkId,
}) => {
  const { claimId } = useParams();

  const [network, setNetwork] = useState(null);
  // store modal states for all modals
  // in this file
  const [isModal, setIsModal] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const isVIP =
    beneficiary &&
    beneficiary.client_class &&
    beneficiary.client_class.slug === "memberClass_vip";

  if (isLoading)
    return (
      <div className="bg-white text-danger rounded-1 p-2">
        <MultiSkeleton length={1} />
      </div>
    );

  return (
    !isLoading &&
    beneficiary && (
      <Fragment>
        <div className="d-flex align-items-center">
          {isVIP ? (
            <Badge
              color="primary"
              className="font-size-16 align-self-start ms-2"
            >
              {beneficiary?.client_class?.name}
            </Badge>
          ) : null}
          <ul className="list-unstyled hstack gap-1  ms-2 flex-wrap">
            <li>
              <Link
                className="badge bg-success fw-semibold py-2 px-2"
                to={`${stakeholderPaths.beneficiaryList}/view/${beneficiary?.id}`}
                target="_blank"
              >
                {buttonsTitles.viewProfile}
              </Link>
            </li>
            <li>
              <button
                aria-label="Beneficiary Medical File"
                className="border-0 badge bg-success fw-semibold py-2 px-2"
                onClick={() => setIsModal(modalsStates.medicalFilesModal)}
              >
                {buttonsTitles.medicalFile}
              </button>
            </li>
            <li>
              <button
                aria-label="Beneficiary FOC Spent"
                className="border-0 badge bg-success fw-semibold py-2 px-2"
                onClick={() => setIsModal(modalsStates.focSpent)}
              >
                {buttonsTitles.focSpent}
              </button>
            </li>
            <li>
              <Link
                className="badge bg-success fw-semibold py-2 px-2"
                target="_blank"
                to={{
                  pathname: `${stakeholderPaths.beneficiaryList}/view/${beneficiary?.id}#${beneficaryBenefitTOBId}`,
                  state: { id: beneficaryBenefitTOBId },
                }}
              >
                {buttonsTitles.tableofBenefits}
              </Link>
            </li>
            <li>
              <button
                className="badge border-0 bg-success fw-bold py-2 px-2"
                onClick={() => setIsModal(modalsStates.sepcialNotes)}
              >
                {buttonsTitles.viewNotes}
              </button>
            </li>

            {/* networks */}
            {beneficiary?.policy?.product?.product_networks?.map(
              (productNetwork) => {
                const isNetworkFoundInPlans =
                  beneficiary?.co_participations?.length > 0 &&
                  beneficiary?.co_participations?.find(
                    (plan) =>
                      plan?.benefit?.id === productNetwork?.benefit?.id &&
                      plan?.class?.id === productNetwork?.class?.id
                  )
                    ? true
                    : false;
                return isNetworkFoundInPlans ? (
                  <li
                    className="badge bg-success fw-semibold py-2 px-2"
                    key={`${productNetwork.id}-${productNetwork?.benefit?.id}`}
                    onClick={() => {
                      setIsModal(modalsStates.networkModal);
                      setNetwork({
                        id: productNetwork.id,
                        name: productNetwork?.network?.name,
                      });
                    }}
                  >
                    {productNetwork?.network?.name?.substring(0, 9) + "..."}
                  </li>
                ) : null;
              }
            )}
            {claimId ? (
              <li className="position-relative">
                <button
                  className="badge border-0 bg-success fw-bold py-2 px-2"
                  onClick={() => setIsModal(modalsStates.chatModal)}
                >
                  {buttonsTitles.chat}

                  {claim?.last_conversation &&
                    claim?.last_conversation?.last_message &&
                    claim?.last_conversation?.last_message?.user?.access_type
                      ?.id === 1 && (
                      <span
                        className="badge bg-danger d-flex align-items-center justify-content-center rounded-circle align-self-baseline position-absolute"
                        style={{
                          width: "15px",
                          height: "15px",
                          right: "-3px",
                          top: "-10px",
                        }}
                      >
                        <i className="bx bx-bell bx-tada font-size-12" />
                      </span>
                    )}
                </button>
              </li>
            ) : null}

            {/* send wattsapp button */}
            {claimId ? (
              <Fragment>
                <Dropdown
                  isOpen={isMenuOpen}
                  toggle={() => setIsMenuOpen(!isMenuOpen)}
                  className="align-self-center"
                  // className="badge border-0 bg-success fw-bold py-2 px-2"
                >
                  <DropdownToggle
                    className="badge border-0 bg-success fw-bold py-2 px-2"
                    caret
                  >
                    {buttonsTitles.send}
                    <i className="mdi mdi-chevron-down px-1" />
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-end">
                    <button
                      className="dropdown-item"
                      onClick={() => {
                        setIsModal(modalsStates.whatAppModal);
                        setIsMenuOpen(false);
                      }}
                    >
                      {buttonsTitles.sendWhatsapp}
                    </button>
                    <button
                      className="dropdown-item"
                      onClick={() => {
                        setIsMenuOpen(false);
                      }}
                    >
                      {buttonsTitles.sendEmail}
                    </button>
                  </DropdownMenu>
                </Dropdown>
              </Fragment>
            ) : null}
          </ul>
        </div>

        {isModal === modalsStates.sepcialNotes && (
          <SpecialNotesModal
            show={isModal === modalsStates.sepcialNotes}
            onCloseClick={() => setIsModal(false)}
            beneficaryId={beneficiary?.id}
            beneficary={beneficiary}
          />
        )}
        {isModal === modalsStates.chatModal && (
          <ChatModal
            show={isModal === modalsStates.chatModal}
            onCloseClick={() => setIsModal(false)}
            resourceId={claimId}
            beneficiary={beneficiary}
            claim={claim}
            // beneficary={beneficiary}
          />
        )}

        {isModal === modalsStates.medicalFilesModal && (
          <MedicalFilesTableModal
            show={isModal === modalsStates.medicalFilesModal}
            onCloseClick={() => setIsModal(false)}
            beneficiaryId={beneficiary?.id}
          />
        )}

        {isModal === modalsStates.focSpent && (
          <FocSpentModal
            show={isModal === modalsStates.focSpent}
            onCloseClick={() => setIsModal(false)}
            beneficiaryId={beneficiary?.id}
          />
        )}

        {isModal === modalsStates.networkModal && (
          <NetworkContractsModal
            show={isModal === modalsStates.networkModal}
            onCloseClick={() => setIsModal(false)}
            networkId={network?.id}
            networkName={network?.name}
          />
        )}

        {isModal === modalsStates.whatAppModal && (
          <WhatssAppModal
            isModal={isModal === modalsStates.whatAppModal}
            setIsModal={setIsModal}
            claim={claim}
          />
        )}
      </Fragment>
    )
  );
};

export default ClaimsBreadcrumbAddons;
